<template>
  <div class="department px-2">
    <div class="search pt-3">
      <div class="row mb-4">
        <div class="flex md8 xs12">
          <va-input label="姓名" placeholder="请输入关键字" v-model="keyword" clearable/>
        </div>
        <div class="flex md4 xs12">
          <va-button :rounded="false" class="px-2" @click="searchRecords">搜索</va-button>
        </div>
      </div>
    </div>
    <div class="ribbon-btn py-1">
      <va-button icon="add" size="small" flat :rounded="false" @click="gotoNewPage()">新增</va-button>
      <va-button icon="delete" size="small" :rounded="false" flat :disabled="selectedItems.length <= 0" @click="deleteRecords">删除</va-button>
      <va-button icon="edit" size="small" :rounded="false" flat :disabled="selectedItems.length != 1" @click="editClicked">明细</va-button>
    </div>
    <div class="table">
      <ag-grid-vue style="height: 100%;"
        class="ag-theme-material"
        :columnDefs="columnColumnDefs"
        :headerHeight="45"
        rowSelection="multiple"
        @selection-changed="onSelectionChanged"
        @grid-ready="onGridReady">
      </ag-grid-vue>
    </div>
    <div class="pagination">
      <v-pagination
        v-model="currentPage"
        :pages="pageCount"
        :range-size="pageSize"
        @update:modelValue="currengPageChanged"
        active-color="#DCEDFF" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed, getCurrentInstance } from 'vue'
import moment from 'moment'
import { useRouter, useRoute } from 'vue-router'
import axiosService from '@/services/AxiosService'
import selectOptionService from '@/services/SelectOptionService'
import { AgGridVue, } from "ag-grid-vue3"
import { GridReadyEvent, GridApi } from "ag-grid-community";
import VPagination from "@hennge/vue3-pagination"
import { User, UserInGrid } from '@/model'
import { AxiosResponse } from 'axios'
import "@/sass/pagination.scss"

export default defineComponent({
  name: 'department',
  components: {
    AgGridVue,
    VPagination,    
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const app = getCurrentInstance()
    const users = reactive([] as Array<UserInGrid>)
    const keyword = ref('')
    const deleteConfirmModal = ref<any>(null)
    const confirmToDelete = ref(false)
    let gridApi: GridApi
    let gridColumnApi = null as any
   
    const columnColumnDefs = [
        { headerName: "序号", field: "serialNo", width:120,checkboxSelection: true, headerCheckboxSelection: true },
        { headerName: "登录名", field: "userName", resizable:true },
        { headerName: "姓名", field: "fullName", width:250, resizable:true },
        { headerName: "职位", field: "title",width:250, resizable:true },
        { headerName: "手机号码", field: "mobile", resizable:true },
        { headerName: "邮箱", field: "email",width:250, resizable:true },
        { headerName: "所属部门", field: "department.name", resizable:true },
        { headerName: "系统角色", field: "rolesInString", width:200, resizable:true },
        { headerName: "电话", field: "telephoneNo", resizable:true },
        { headerName: "", flex:1 } // placeholder column
    ]
    let recordCount = ref(1)
    const currentPage = ref(1)
    const pageSize = ref(30)
    const pageCount = computed(() => Math.ceil(recordCount.value / pageSize.value))
    function currengPageChanged(page: number)
    {
      selectedItems.value.splice(0, selectedItems.value.length)
      currentPage.value = page
      searchRecords()
    }
    let selectedItems = ref([] as Array<UserInGrid>)

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

    function gotoNewPage() {
      router.push('/permission/users/add-user')
    }

    function onSelectionChanged() {
      selectedItems.value.splice(0, selectedItems.value.length)
      Array.prototype.push.apply(selectedItems.value, gridApi.getSelectedRows() as Array<UserInGrid>)
    }

    function onGridReady(params: GridReadyEvent) {
      gridApi = params.api
      gridColumnApi = params.columnApi
      searchRecords()
    }

    function searchRecords() {
      let $filter = ''
      if (keyword.value != '') {
        $filter = `&$filter=contains(fullname,'${keyword.value}')`
      }
      gridApi.showLoadingOverlay();
      axiosService.get(`/odata/users/$count?${$filter}`).then((res: AxiosResponse<number>) => {
        recordCount.value = res.data
      })

      axiosService.get(`/odata/users?&$orderby=createdOn desc&$expand=department($select=id,name),userroles($expand=role($select=id,name,displayName))${$filter}&$top=${pageSize.value}&$skip=${(currentPage.value - 1) * pageSize.value}`).then(res => {
        users.splice(0, users.length)
        Array.prototype.push.apply(
          users,
          res.data.value.map((c: User, index: number) => {
            return ({
              id: c.id,
              serialNo: ((currentPage.value - 1) * pageSize.value ) + (index + 1),
              fullName: c.fullName,
              userName: c.userName,
              title: c.title,
              mobile: c.mobile,
              telephone: c.telephone,
              email: c.email,
              departmentId: c.departmentId,
              department: c.department,
              createdOn: c.createdOn,
              rolesInString: c.userRoles.map(ur => ur.role.displayName).join(', ')
            }) as UserInGrid
          }
        ))
        gridApi.setRowData(users)
        gridApi.hideOverlay()
      })
    }


    function selectionChange(evt: any)
    {
      selectedItems.value = evt.currentSelectedItems
    }

    function editClicked()
    {
      const id = selectedItems.value[0].id
      router.push(`/permission/users/${id}`)
    }

    function deleteRecords() {
      if(selectedItems.value.length <= 0)
      {
        toast({
          message: '请至少选择一条记录',
          color: 'warning',
          closeable: true,
          duration: 2000
        })
      }

      if(window.confirm("请确认是否删除？")) {
        selectedItems.value.forEach(c => {
          axiosService.delete(`api/users/${c.id}`).then((res) => {
            selectedItems.value.splice(selectedItems.value.findIndex(s => s.id == c.id),1)
            toast({
              message: '删除成功！',
              color: 'success',
              closeable: true,
              duration: 2000
            })
            searchRecords()
          })
        })
      }
    }

    return {
      name: 'corporate',
      keyword,
      columnColumnDefs,
      gotoNewPage,
      searchRecords,
      selectedItems,
      selectionChange,
      currentPage,
      onGridReady,
      editClicked,
      deleteRecords,
      onSelectionChanged,
      pageCount,
      pageSize,
      currengPageChanged,
      deleteConfirmModal,
      confirmToDelete
    }
  }
})
</script>
<style lang="scss" scoped>
.department {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #fff;
  .search {
  }
  .ribbon-btn::v-deep {
    .va-button--small .va-button__content {
      padding: 0 0.8rem;
    }
  }
  .table {
    flex: 1;
    position: relative;
    .container {
      position: absolute;
    }
    table table
    {
      position: absolute;
      top: 0px;
      thead th{
        text-align: left !important;
        background-color: #274583;;
      }
    }
  }

  .pagination
  {
    display: flex;
    justify-content: right;
  }
}
</style>